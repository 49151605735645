import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="splide-slider-wrapper${ x => x.isCarousel ? ' is-carousel' : ''}">
    <div class="splide-slider-container">
        <div class="splide">
            <div class="splide__track">
                <ul class="splide__list">            
                    ${T.repeat(x => x.images, T.html`
                        ${T.when(x => x.big, T.html`<li class="splide__slide"><a class="img-link" target="_blank" href="${ x => x.big }">
                            <img alt="" src="${ x => x.small }"/>
                        </a></li>`)}

                        ${T.when(x => !x.big, T.html`<li class="splide__slide">
                            <img alt="" src="${ x => x.small }"/>
                        </li>`)}
                    `, { positioning: true })}                
                </ul>
            </div>
        </div>
    </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
}

.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
  z-index: 1;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}
@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: 3px solid #0bf;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: 3px solid #0bf;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.img-link {
  display: block;
  height: 100%;
}

.is-carousel .img-link {
  border-left: 2px solid var(--heading-color, #E9C476);
}

.is-carousel .splide {
  border: 2px solid var(--heading-color, #E9C476);
  border-radius: 4px;
}

.splide .splide__track .splide__pagination__page.is-active {
  background: var(--heading-color, #E9C476);
}
.splide ul.splide__list {
  list-style: none;
}
.splide ul.splide__list li .img-link img {
  width: 100%;
}
.splide ul.splide__list li.is-active .img-link {
  border-left: none;
}
.splide ul.splide__list li.is-active .img-link img {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.splide .splide__arrow {
  background-color: var(--heading-color, #E9C476);
}
.splide .splide__arrow svg path {
  fill: #FFF;
}
.splide .splide__pagination__page {
  border: 2px solid #FFF;
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-right: 6px;
}
.splide .splide__pagination__page.is-active {
  background-color: var(--heading-color, #E9C476);
}

@media screen and (max-width: 720px) {
  .splide__pagination {
    display: none;
  }
}`;


import { RWSViewComponent, RWSView, observable, attr } from '@rws-framework/client';
import Splide, { Options } from '@splidejs/splide';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

export type SplideOpts = Partial<Options>;

const _defaultSplideOpts: SplideOpts = {
    type: 'loop',
    start: 0,
    perPage: 1,
    rewind: false,
    autoplay: true,
    interval: 3000,
    pauseOnHover: true,
    speed: 800,    
    autoHeight: true,
    easing: 'ease-in-out',
};

export type SplideImageEntry = {
    big: string,
    small: string
}

@RWSView('splide-slider', null, { template: rwsTemplate, styles })
class SplideSlider extends RWSViewComponent  {  
    @attr mode: 'slider' | 'carousel' = 'slider';
    @attr lightbox: 'enabled' | 'disabled' = 'enabled';

    @observable images: SplideImageEntry[] = [];
    @observable slider: Splide | null = null;

    @observable options: SplideOpts = null;
    @observable isCarousel: boolean = false;
    


    connectedCallback(): void {      
        super.connectedCallback();          
        this.setupSplide();

        if(this.mode === 'carousel'){
            this.isCarousel = true;
        }
    }

    setupSplide(options: SplideOpts = _defaultSplideOpts): void {
        if(this.options){
            options = {...options, ...this.options};
        }

        const splideEl = this.$('.splide-slider-container .splide') as HTMLDivElement;                
        if (splideEl) {        
            this.slider = new Splide(splideEl, options).mount();
            this.slider.go(this.images.length - 1);            
            setTimeout(() => {
                this.slider.go(0);

                
                this.initLightBox();
            }, 500);
        } else {
            console.error('Splide element not found');
        }
    }

    imgClick(event: Event): void
    {
        event.preventDefault();        

    }

    private initLightBox() {
        const lightbox = new PhotoSwipeLightbox({
            gallery: this.$('.splide__list') as NodeListOf<HTMLElement>,
            children: 'a.img-link',
            pswpModule: () => import('photoswipe')
        });
        
        lightbox.init();    
    }
}

SplideSlider.defineComponent();

export { SplideSlider };

